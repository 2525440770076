import React, {Component} from 'react';
import PropTypes from 'prop-types';
import logo from './images/fabric.png';
import appleWatch from './images/apple-watch.png';
import sushi from './images/sushi2.jpg';
import print from './images/prter.png';
import print2 from './images/fmea.jpg';
import print3 from './images/prusa.jpg';
import drone from './images/drone.png';
import drone2 from './images/drone2.png';
import drone3 from './images/drone3.png';
import desktop from './images/desktop.png';
import toggles from './images/toggles.png';
import trophy from './images/trophy.png';
import login from './images/login (1).svg';
import discount from './images/online1.svg';
import sale from './images/sale.png';
import networking from './images/connection.svg';
import firebase from './firebase'
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input'

function validatePhoneNumber(inputtxt) {
    var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    if(inputtxt.value.match(phoneno)) {
        return true;
    }
    else {
        return false;
    }
}
function setInputFilter(textbox, inputFilter) {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
        textbox.addEventListener(event, function () {
            if (inputFilter(this.value)) {
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty("oldValue")) {
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            }
        });
    });
}
function addClassToId(id, className) {
    var element, name, arr;
    element = document.getElementById(id);
    name = className;
    arr = element.className.split(" ");
    if (arr.indexOf(name) == -1) {
        element.className += " " + name;
    }
}
function removeClassToId(id, classname) {
    var element = document.getElementById(id);
    element.className = element.className.replace(classname, "");

}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export default class Product extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            name:'',
            lastName: '',
            email: '',
            country: 'Argentina',
            birth: '2000-12-25',
            tel: '',
            uid: '',
            ID: '',
            friendUID: window.location.href.substr(window.location.href.toString().lastIndexOf('/') + 1),
            headerText: '',
        };
    }

    componentDidMount() {
        if (this.state.friendUID){
            var db = firebase.firestore();
            const context = this;
            db.collection('users').doc(this.state.friendUID).get()
                .then(function (doc) {
                    if (doc.exists) {
                        context.setState({
                            headerText:'¡' +  doc.data().name +' te invitó a conocer el nuevo local de Fabric Sushi en Lomitas!',
                        })
                    }
                    else {
                        context.setState({
                            headerText:'Fabric Sushi llega a Lomitas y queremos que lo conozcas',
                        })
                    }
            })
        }
        else {
            this.setState({
                headerText:'Fabric Sushi llega a Lomitas y queremos que lo conozcas',
            })
        }




    }


    handleChangeTel = (value) => {

        if (value){
            if (value.length >= 0) {
                this.setState({ tel: formatPhoneNumber(value, 'International') })
            } else {
                this.setState({
                    tel: value,
                })
            }
        }

    };

    testScript = () => {
       var db = firebase.firestore();
       var ref = db.collection('users');

       for (var i = 0; i<11 ; i++){
           ref.add({
               name: i.toString(),
               email: 'juanstein157@gmail.com'
           })
       }

    };

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
        });
        removeClassToId('formName', 'has-error');
        removeClassToId('formLastName', 'has-error');
        removeClassToId('formEmail', 'has-error');
        removeClassToId('formBirth', 'has-error');
        removeClassToId('formTel', 'has-error');
        removeClassToId('formCountry', 'has-error');
    };

    fbLoginClick = () => {
        var provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().useDeviceLanguage();
        addClassToId('next-personal', 'sending');

        const context = this;





        firebase.auth().signInWithRedirect(provider).then(function(result) {
            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;

            removeClassToId('next-personal', 'sending');
            addClassToId('next-personal', 'is-success');
            addClassToId("js-product-info", "slide-out-left");
            addClassToId("js-personal-info","slide-in-right");

            var splitedString = user.displayName.split(' ');

            var firstName = splitedString[0];
            var lastName = '';

            for (var i=1; i<splitedString.length; i++){
                if (i === 1){
                    lastName = splitedString[i];
                }
                else {
                    lastName = lastName + ' ' + splitedString[i];
                }
            }
            context.setState({
                name: firstName,
                lastName: lastName,
                email: user.email,
                uid: user.uid,
            });

            // ...
        }).catch(function(error) {
            // Handle Errors here.

            alert(error.code + error.message);
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
        });


    };
    onBackClick = () => {
        removeClassToId("js-product-info", "slide-out-left");
        removeClassToId("js-personal-info","slide-in-right");

    };

    onSubmit = () => {
        if (this.state.name && this.state.lastName && this.state.email && this.state.birth && this.state.tel && this.state.country){
            if (validateEmail(this.state.email)){
                addClassToId('load-user', 'sending');
                var db = firebase.firestore();
                const context = this;
                db.collection('users').where('campaign', '==', 'thekitmaker').where('email','==', this.state.email).get()
                    .then(function (snapshot) {
                       if (snapshot.size > 0){
                           removeClassToId('load-user', 'sending');
                           addClassToId('load-user', 'is-success');
                           removeClassToId("js-personal-info","slide-in-right");
                           addClassToId("js-personal-info", "slide-out-left");
                           addClassToId("js-submit","slide-in-right");
                           snapshot.forEach(function(doc) {
                               // doc.data() is never undefined for query doc snapshots
                               context.setState({ID: doc.id})
                           });

                       }else {
                           db.collection('users').add({
                               name: context.state.name,
                               lastName: context.state.lastName,
                               email: context.state.email,
                               birth: context.state.birth,
                               tel: context.state.tel,
                               country: context.state.country,
                               friendUID: context.state.friendUID,
                               campaign: 'thekitmaker'
                           }).then(function (doc) {
                               context.setState({ID: doc.id});
                               removeClassToId('load-user', 'sending');
                               addClassToId('load-user', 'is-success');
                               removeClassToId("js-personal-info","slide-in-right");
                               addClassToId("js-personal-info", "slide-out-left");
                               addClassToId("js-submit","slide-in-right");
                           })
                       }
                    });
                /*var docRef = db.collection("users").doc(this.state.uid);
                docRef.get().then(function(doc) {
                    if (doc.exists) {
                        removeClassToId('load-user', 'sending');
                        addClassToId('load-user', 'is-success');
                        removeClassToId("js-personal-info","slide-in-right");
                        addClassToId("js-personal-info", "slide-out-left");
                        addClassToId("js-submit","slide-in-right");
                    } else {
                        docRef.set({
                            name: context.state.name,
                            lastName: context.state.lastName,
                            email: context.state.email,
                            birth: context.state.birth,
                            tel: context.state.tel,
                            country: context.state.country,
                            friendUID: context.state.friendUID
                        }).then(function (doc) {
                            removeClassToId('load-user', 'sending');
                            addClassToId('load-user', 'is-success');
                            removeClassToId("js-personal-info","slide-in-right");
                            addClassToId("js-personal-info", "slide-out-left");
                            addClassToId("js-submit","slide-in-right");
                        })
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
                */
            }
            else {
                addClassToId('formEmail', 'has-error');
            }
        }
        else {
            addClassToId('formName', 'has-error');
            addClassToId('formLastName', 'has-error');
            addClassToId('formEmail', 'has-error');
            addClassToId('formBirth', 'has-error');
            addClassToId('formTel', 'has-error');
            addClassToId('formCountry', 'has-error');
        }
    };
    render() {
        return (
            <div>
                <div id="loading">
                    <div id="loading-center">
                        <div id="loading-center-absolute">
                            <div className="object" id="object_four"></div>
                            <div className="object" id="object_three"></div>
                            <div className="object" id="object_two"></div>
                            <div className="object" id="object_one"></div>
                        </div>
                    </div>
                </div>
                <header className="row" id="header">
                    <nav className="navbar navbar-default navbar-fixed-top">
                        <div className="container">

                            <div className="navbar-header">
                                <a className="navbar-brand" href="#header"><img src={logo} alt=""/></a>
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                        data-target="#main-navbar" aria-expanded="false">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>

                            <div className="collapse navbar-collapse" id="main-navbar">
                                <a href="#how-it-works" className=" btn btn-warning pull-right hidden-sm hidden-xs">OBTENER
                                    DESCUENTO</a>
                                <ul className="nav navbar-nav navbar-right">
                                    <li><a href="#how-it-works">Como funciona</a></li>
                                </ul>
                            </div>

                        </div>

                    </nav>
                    <div className="top-banner row m0 text-center fadeInOnLoad">
                        <div className="container">
                            <h2>{this.state.headerText}</h2>
                            <p> Te regalamos un cocktail de bienvenida para que disfrutes tu cena en el restaurant o un 10% de descuento si querés hacer Takeaway.
                                El beneficio es válido para un uso por persona desde el 05/04/2019 hasta el 30/04/2019.
                                <br/>*Beneficio no acumulable con otras promociones.
                            </p>
                            <a href="#how-it-works" className="btn btn-primary btn-lg" id="superDiscount">¡Quiero mi beneficio!</a>
                            <div className="row apple-watch">
                                <img src={sushi} alt="" className="watch_img"/>
                            </div>
                        </div>
                    </div>
                </header>
                {/*  <section className="row the-product" id="product">
                    <div className="container">
                        <div className="row section-header wow fadeInUp">
                            <h2>the product</h2>
                            <p>Introduce the product here. A small description about what it is and how it helps the
                                user. You can also add some photos below.</p>
                        </div>
                        <FlowSlider/>

                    </div>
                </section>*/}
                <section className="row how-it-works" id="how-it-works">
                    <div className="container">
                        <div className="row section-header v2 wow fadeInUp">
                            <h2>como funciona</h2>
                            <p>Tener tu beneficio es muy fácil. Todo lo que tienes que hacer es seguir estos 3
                                simples pasos:</p>
                        </div>
                        <div className="row work-processes">

                            <div className="col-sm-4 work-process wow fadeIn">
                                <div className="row m0 process-icon">
                                    <img src={login} alt=""/>
                                </div>
                                <h3>Login con Facebook</h3>
                                <p>Primero te pediremos que inicies sesión con tu cuenta de facebook. Nos tenemos
                                    que asegurar que le estamos dando el beneficio a un humano 😉</p>
                            </div>

                            <div className="col-sm-4 work-process wow fadeIn" data-wow-delay="0.5s">
                                <div className="row m0 process-icon">
                                    <img src={discount} alt=""/>
                                </div>
                                <h3>Revisá tus mails</h3>
                                <p>Recibirás en tu correo el código de descuento para usar en el local.</p>
                            </div>
                            <div className="col-sm-4 work-process wow fadeIn" data-wow-delay="1s">
                                <div className="row m0 process-icon">
                                    <img src={networking} alt=""/>
                                </div>
                                <h3>Compártelo con tus amigos!</h3>
                                <p>¡Hacelo viral! Luego de ingresar tus datos tendrás la opción de invitar a todos tus amigos o conocidos con tu invitación personalizada.</p>
                            </div>
                            {/*<div className="col-sm-3 work-process wow fadeIn" data-wow-delay="1.5s">
                                <div className="row m0 process-icon">
                                    <img src={sale} alt=""/>
                                </div>
                                <h3>Usa tu código!</h3>
                                <p>Ingresa en la tienda y en el campo indicado, introduce tu código antes de finalizar
                                    la compra.</p>
                            </div>*/}


                        </div>
                        <div className="row m0">
                            <button onClick={this.fbLoginClick}
                                    className="btn btn-rounded js-preorder-btn btn-block"
                                    id="next-personal">
                                <span>¡REGISTRATE Y EMPEZA!</span>
                            </button>
                        </div>
                    </div>
                </section>
                <footer className="row">
                    <div className="container">
                        <div className="row m0 social-links">
                            <ul className="nav">
                                <li className="wow fadeInUp"><a href="https://www.facebook.com/fabricsushi/"><i className="fab fa-facebook"/></a></li>
                                <li className="wow fadeInUp" data-wow-delay="0.1s"><a href="https://www.instagram.com/fabricsushi/">
                                    <i className="fab fa-instagram"/></a></li>

                            </ul>
                        </div>
                        <div className="row m0 menu-rights">
                            <ul className="nav footer-menu">
                                <li><a href="https://getviral.me">About</a></li>
                                <li><a href="https://termsfeed.com/terms-conditions/9b37025d3dc3da1bbb078f78835393c4">Terms of Use</a></li>
                            </ul>
                            <p>Copyright © 2019. GetViral.
                                <br className="small-divide"/> All rights reserved</p>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}