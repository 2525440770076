import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PhoneInput, {formatPhoneNumber} from "react-phone-number-input";
import logo from "./images/logo.png";
import firebase from "./firebase";
import $ from 'jquery';

function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}

function addClassToId(id, className) {
    var element, name, arr;
    element = document.getElementById(id);
    name = className;
    arr = element.className.split(" ");
    if (arr.indexOf(name) == -1) {
        element.className += " " + name;
    }
}
function removeClassToId(id, classname) {
    var element = document.getElementById(id);
    element.className = element.className.replace(classname, "");

}

export default class Form extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            nameFromDB: '',
            dniFromDB: '',
            lastNameFromDB: '',
            name:'',
            lastName: '',
            email: '',
            dni: '',
            ID: window.location.href.substr(window.location.href.toString().lastIndexOf('/') + 1),
            headerText: '',
            campaignName: '',
            discAmount: '',
            endDate: '',
            campID: '',
            otherBen: '',
            pocCode: '',
        };
    }

    onSubmit = () => {

        removeClassToId('next-personal', 'sending');
        addClassToId('next-personal', 'is-success');
        addClassToId("js-product-info", "slide-out-left");
        addClassToId("js-personal-info","slide-in-right");



    };

    onSubmit2 = () => {

        if (this.state.pocCode){
            addClassToId('load-user', 'sending');
            var db = firebase.firestore();
            var context = this;
            db.collection('users').doc(this.state.ID).update({validated: true, pocCode: this.state.pocCode}).then(function (doc) {
                    removeClassToId('load-user', 'sending');
                    addClassToId('load-user', 'is-success');
                    removeClassToId("js-personal-info", "slide-in-right");
                    addClassToId("js-personal-info", "slide-out-left");
                    addClassToId("js-submit", "slide-in-right");
                    var campRef = db.collection('campaigns').doc(context.state.campID);
                    db.runTransaction(function (transaction) {
                        return transaction.get(campRef).then(function (camp) {
                            if (!camp.exists) {
                                throw "Document does not exist!";
                            }
                            var newConversions = camp.data().conversions + 1;

                            transaction.update(campRef, {conversions: newConversions});
                            return newConversions;

                        });
                    }).then(function (newPopulation) {

                    }).catch(function (err) {
                        // This will be an "population is too big" error.
                        console.error(err);
                    });
                }
            )
        }
        else {
            addClassToId('formName', 'has-error');
        }





    };


    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
        });
        removeClassToId('formName', 'has-error');
        removeClassToId('formLastName', 'has-error');
        removeClassToId('formEmail', 'has-error');
        removeClassToId('formCountry', 'has-error');
    };
    componentDidMount() {

        if (this.state.ID){
            var db = firebase.firestore();
            var context = this;
            db.collection('users').doc(this.state.ID).get().then(function (doc) {
                if (doc.exists){

                    context.setState({nameFromDB: doc.data().name, dniFromDB: doc.data().dni, emailfromDB: doc.data().email,
                    lastNameFromDB: doc.data().lastName, campID: doc.data().campaign});
                    db.collection('campaigns').doc(doc.data().campaign).get().then(function (camp) {

                        if (camp.exists) {

                            context.setState({campaignName: camp.data().name, discAmount: camp.data().discAmount, endDate: camp.data().endDate, otherBen: camp.data().otherBen});
                            $("#loading").fadeOut();
                            $("#loading .object").delay(700).fadeOut("slow");
                            $('.fadeInOnLoad').delay(700).fadeTo("slow", 1);
                        }

                    })
                }
            })
        }

    }

    render() {
        return (

            <div className={'rediFormWrapper fadeInOnLoad'}>

                <div id="js-product-info" className="product-info redirectForm">
                    <h4 className="top-banner h2">Redeem your beer</h4>
                    <h2 className="edition">Hello {this.state.nameFromDB}! Are you sure you want to redeem your in {this.state.campaignName}?</h2>
                    <h5 className="model">Remember to make this step only if you are asked to do so.</h5>
                    {this.state.discAmount !== 0 ?
                        <h5 className="price">
                            <span className="label label-default">{this.state.discAmount}% discount</span> Till {this.state.endDate}
                        </h5> :
                        <h5 className="price">
                            <span className="label label-default">{'Una ' + this.state.otherBen}</span> Till {this.state.endDate}
                        </h5>
                    }





                    <div className="submit-area row m0">
                        <button onClick={this.onSubmit}
                                className="btn btn-rounded js-preorder-btn btn-block"
                                id="next-personal">
                            <span>CONTINUE</span>
                        </button>
                    </div>
                </div>
                <div className="personal-info redirectForm " id="js-personal-info">
                    <div className="form-group text-center">
                        {/*<a href="javascript:void(0);" className="btn" onClick={this.onBackClick}
                                                                           id="prev-product-info">Go Back</a>*/}
                        <h4 className="name">Confirm your info</h4>
                    </div>
                   {/* <div className="row">
                        <div className="form-group col-sm-6" id={'formName'}>
                            <input type="text" name="first_name" className="form-control"
                                   placeholder="Nombre" required value={this.state.name}
                                   onChange={this.handleChange('name')}/>
                        </div>
                        <div className="form-group col-sm-6" id={'formLastName'}>
                            <input type="text" name="last_name" className="form-control"
                                   placeholder="Apellido" required value={this.state.lastName}
                                   onChange={this.handleChange('lastName')}/>
                        </div>
                    </div>
                    <div className="form-group" id={'formEmail'}>
                        <input type="email" name="email" className="form-control"
                               placeholder="E-mail" required value={this.state.email}
                               onChange={this.handleChange('email')}/>
                    </div>*/}
                    <h2 className="edition checkMail">
                        Name: {this.state.nameFromDB + " " + this.state.lastNameFromDB} <br/>
                        Email: {this.state.emailfromDB} <br/>
                        DNI: {this.state.dniFromDB}
                    </h2>
                    <div className="form-group col-sm-6" id={'formName'}>
                        <h3>POC Code</h3>
                        <input type="text" name="first_name" className="form-control"
                               placeholder="POC code" required value={this.state.pocCode}
                               onChange={this.handleChange('pocCode')}/>
                    </div>


{/*
                    <div className="form-group" id={'formCountry'}>
                        <input type="number" name="DNI" className="form-control"
                               placeholder="DNI"
                               required value={this.state.dni} onChange={this.handleChange('dni')}/>
                    </div>*/}

                    <div className="submit-area-2 row m0">
                        <button
                            onClick={this.onSubmit2}
                            id='load-user'
                            className="btn btn-rounded js-preorder-btn btn-block">
                            <span>Continue</span>
                        </button>

                    </div>
                </div>
                <div className="personal-info redirectForm" id="js-submit">
                    <h2 className="edition checkMail">Congratulations! Show this screen to you waiter to check your benefit</h2>
                    <h2 className="edition">Name: {this.state.nameFromDB + ' ' + this.state.lastNameFromDB}</h2>
                    <h2 className="edition">DNI: {this.state.dniFromDB}</h2>
                </div>
            </div>
        )
    }
}