import React, { Component } from 'react';
import logo from './images/logo.png';
import './App.css';
import Product from "./Product";
import WOW from 'wowjs';
import Form from "./Form";
import firebase from './firebase'
import $ from 'jquery';
import {formatPhoneNumber} from "react-phone-number-input";

function validatePhoneNumber(inputtxt) {

    var phoneno = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g;
    if(inputtxt.match(phoneno)) {
        return true;
    }
    else {
        return false;
    }
}

function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}
function addClassToId(id, className) {
    var element, name, arr;
    element = document.getElementById(id);
    name = className;
    arr = element.className.split(" ");
    if (arr.indexOf(name) == -1) {
        element.className += " " + name;
    }
}
function removeClassToId(id, classname) {
    var element = document.getElementById(id);
    element.className = element.className.replace(classname, "");

}
function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ID: window.location.href.substr(window.location.href.toString().lastIndexOf('/') + 1),
            validated: true,
        };
    }





    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();



        if (this.state.ID){
            var db = firebase.firestore();
            var context = this;
            db.collection('users').doc(this.state.ID).get().then(function (doc) {
                if (doc.exists){
                    context.setState({validated: doc.data().validated});
                    if (doc.data().validated){
                        $("#loading").fadeOut();
                        $("#loading .object").delay(700).fadeOut("slow");
                        $('.fadeInOnLoad').delay(700).fadeTo("slow", 1);
                    }

                }else {
                    $("#loading").fadeOut();
                    $("#loading .object").delay(700).fadeOut("slow");
                    $('.fadeInOnLoad').delay(700).fadeTo("slow", 1);
                }
            }).catch(function (error) {
                $("#loading").fadeOut();
                $("#loading .object").delay(700).fadeOut("slow");
                $('.fadeInOnLoad').delay(700).fadeTo("slow", 1);
            })
        }
        else {
            $("#loading").fadeOut();
            $("#loading .object").delay(700).fadeOut("slow");
            $('.fadeInOnLoad').delay(700).fadeTo("slow", 1);
        }



    }

    render() {
        return (
            <div className="App">
                <div id="loading">
                    <div id="loading-center">
                        <div id="loading-center-absolute">
                            <div className="object" id="object_four"></div>
                            <div className="object" id="object_three"></div>
                            <div className="object" id="object_two"></div>
                            <div className="object" id="object_one"></div>
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-default">
                    <div className="container">

                        <div className="navbar-header">
                            <a className="navbar-brand" href="#header"><img src={logo} alt=""/></a>
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                    data-target="#main-navbar" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>


                    </div>

                </nav>
                {this.state.validated ?
                    <div className={'rediFormWrapper fadeInOnLoad'}>
                        <div className={'invalidWrapper redirectForm'}>
                            <h2 className="edition checkMail invalid">This code has already been redeemed</h2>
                        </div>
                    </div>
                     : <Form/>}

            </div>
        );
    }
}

export default App;
